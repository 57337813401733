<template>
    <div class="h15Box">
        <div class="hbBox1">
            <div class="hb1Box">
                <div class="hbLeft">
                    <p class="hl1 wow animate__animated animate__slideInLeft">企业网站</p>
                    <p class="hl2 wow animate__animated animate__slideInLeft">突出企业核心特点 全面展示企业优势</p>
                    <p class="hl3 wow animate__animated animate__slideInLeft">
                        官方网站是企业对外宣传交流的重要窗口，威有科技官网建设，
                        能够抓住企业特点，提供合适企业的官网设计方案。通过网站目标定位，
                        针对性选择布局，重点展示了客户所关心的内容；并以简洁明朗的设计风格，
                        清晰展现公司水平实力，确保一秒抓住目标用户眼球，给用户留下良好的第一印象。
                    </p>
                    <p class="hl4 wow animate__animated animate__slideInLeft">
                        网站功能：产品展示、公信力展示、SEO优化、在线订单、运营统计
                    </p>
                    <div class="hl5 wow animate__animated animate__slideInLeft">
                        <div class="hl5b">
                            更多介绍
                            <div class="hbCode">
                                <img :src="getWebData().wechat_visitor" alt="" />
                            </div>
                        </div>
                        <div class="hl5b">
                            我要做网站
                            <div class="hbCode">
                                <img :src="getWebData().public_phone" alt="" />
                            </div>
                        </div>
                        <div class="hl5b" @click="goMore">查看更多案例</div>
                    </div>
                    <div class="hl6 wow animate__animated animate__slideInLeft">
                        <ul>
                            <li class="h6Li" v-for="item in 8" :key="item">
                                <a href="#"> <img src="http://iv.okvu.cn/vugw/img/20226295429.png" alt="" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="hbRight">
                    <img
                        src="http://iv.okvu.cn/vugw/img/hc1511.png"
                        alt=""
                        class="hbr1 wow animate__animated animate__slideInUp"
                    />
                    <img
                        src="http://iv.okvu.cn/vugw/img/hc1512.png"
                        alt=""
                        class="hbr2 wow animate__animated animate__slideInUp"
                        data-wow-delay="0.3s"
                    />
                </div>
            </div>
        </div>
        <div class="hbBox2">
            <div class="hb1Box">
                <div class="hbRight">
                    <img
                        src="http://iv.okvu.cn/vugw/img/hc1521.png"
                        alt=""
                        class="hbr1 wow animate__animated animate__bounceInUp"
                    />
                </div>
                <div class="hbLeft">
                    <p class="hl1 wow animate__animated animate__slideInRight">品牌网站</p>
                    <p class="hl2 wow animate__animated animate__slideInRight" data-wow-delay="0.1s">颠覆视觉创新 塑造优秀品牌</p>
                    <p class="hl3 wow animate__animated animate__slideInRight" data-wow-delay="0.2s">
                        品牌网站以品牌营销为目标，搭配多变创新的设计风格， 突出展现企业品牌魅力。威有科技提供的品牌网站建设服务，
                        努力发掘每一品牌的独特记忆点，设定专属的品牌展示角度；
                        并以震撼的视觉冲击力，塑造令人记忆深刻的品牌形象；助力企业扩大品牌知名度，增强品牌效应。
                    </p>
                    <p class="hl4 wow animate__animated animate__slideInRight" data-wow-delay="0.3s">
                        网站功能：产品展示、公信力展示、SEO优化、在线订单、 运营统计
                    </p>
                    <div class="hl5 wow animate__animated animate__slideInRight" data-wow-delay="0.4s">
                        <div class="hl5b">
                            更多介绍
                            <div class="hbCode">
                                <img :src="getWebData().wechat_visitor" alt="" />
                            </div>
                        </div>
                        <div class="hl5b">
                            我要做网站
                            <div class="hbCode">
                                <img :src="getWebData().public_phone" alt="" />
                            </div>
                        </div>
                        <div class="hl5b" @click="goMore">查看更多案例</div>
                    </div>
                    <div class="hl6 wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                        <ul>
                            <li class="h6Li" v-for="item in 8" :key="item">
                                <a href="#"> <img src="http://iv.okvu.cn/vugw/img/20226299352.jpg" alt="" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="hbBox3">
            <div class="hb1Box">
                <div class="hbLeft">
                    <p class="hl1 wow animate__animated animate__slideInLeft">外贸网站</p>
                    <p class="hl2 wow animate__animated animate__slideInLeft">提升品牌形象，获取高质量询盘</p>
                    <p class="hl3 wow animate__animated animate__slideInLeft">
                        外贸网站是企业在互联网上进行产品展示和形象宣传的平台，
                        面向用户主要为国外客户。按照其功能可以分为浏览型外贸网站和营销型外贸网站，
                        二者同时具备产品在线销售、企业宣传、内容营销、品牌沉淀等积极作用，不仅只是企业名片，
                        还深度整合SEO优化，智能询盘，智能翻译等功能，助力提升流量，实现企业盈利。
                    </p>
                    <p class="hl4 wow animate__animated animate__slideInLeft">
                        网站功能：产品展示、公信力展示、SEO优化、在线订单、 运营统计
                    </p>
                    <div class="hl5 wow animate__animated animate__slideInLeft">
                        <div class="hl5b">
                            更多介绍
                            <div class="hbCode">
                                <img :src="getWebData().wechat_visitor" alt="" />
                            </div>
                        </div>
                        <div class="hl5b">
                            我要做网站
                            <div class="hbCode">
                                <img :src="getWebData().public_phone" alt="" />
                            </div>
                        </div>
                        <div class="hl5b" @click="goMore">查看更多案例</div>
                    </div>
                    <div class="hl6 wow animate__animated animate__slideInLeft">
                        <ul>
                            <li class="h6Li" v-for="item in 8" :key="item">
                                <a href="#"> <img src="http://iv.okvu.cn/vugw/img/20226299352.jpg" alt="" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="hbRight">
                    <img
                        src="http://iv.okvu.cn/vugw/img/hc1531.png"
                        alt=""
                        class="hbr1 wow animate__animated animate__slideInUp"
                        data-wow-delay="0.3s"
                    />
                    <img
                        src="http://iv.okvu.cn/vugw/img/hc1532.png"
                        alt=""
                        class="hbr2 wow animate__animated animate__slideInUp"
                    />
                    <img
                        src="http://iv.okvu.cn/vugw/img/hc1533.png"
                        alt=""
                        class="hbr3 wow animate__animated animate__bounceInUp"
                    />
                </div>
            </div>
        </div>
        <div class="hbBox4">
            <div class="hb1Box">
                <div class="hbRight">
                    <img
                        src="http://iv.okvu.cn/vugw/img/hc1541.png"
                        alt=""
                        class="hbr1 wow animate__animated animate__bounceInUp"
                    />
                </div>
                <div class="hbLeft">
                    <p class="hl1 wow animate__animated animate__slideInRight">电商网站</p>
                    <p class="hl2 wow animate__animated animate__slideInRight" data-wow-delay="0.1s">
                        电子商务大时代 汇聚盈利新平台
                    </p>
                    <p class="hl3 wow animate__animated animate__slideInRight" data-wow-delay="0.2s">
                        随着电子商务快速发展，新型电商模式层出不穷。威有科技电商网站建设，
                        能为企业提供括O2O、B2B、B2C、C2C，分销和B2B2C在内的多种电商平台建设，
                        帮助企业开辟稳定的商业营销渠道，牢牢占据网络市场。
                        同时，威有科技还提供个性化营销管理模块，让企业轻松获取订单，高效管理网站。
                    </p>
                    <p class="hl4 wow animate__animated animate__slideInRight" data-wow-delay="0.3s">
                        网站功能：产品展示、公信力展示、SEO优化、在线订单、 运营统计
                    </p>
                    <div class="hl5 wow animate__animated animate__slideInRight" data-wow-delay="0.4s">
                        <div class="hl5b">
                            更多介绍
                            <div class="hbCode">
                                <img :src="getWebData().wechat_visitor" alt="" />
                            </div>
                        </div>
                        <div class="hl5b">
                            我要做网站
                            <div class="hbCode">
                                <img :src="getWebData().public_phone" alt="" />
                            </div>
                        </div>
                        <div class="hl5b" @click="goMore">查看更多案例</div>
                    </div>
                    <div class="hl6 wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                        <ul>
                            <li class="h6Li" v-for="item in 8" :key="item">
                                <a href="#"> <img src="http://iv.okvu.cn/vugw/img/20175101564.jpg" alt="" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="hbBox5">
            <div class="hb1Box">
                <div class="hbLeft">
                    <p class="hl1 wow animate__animated animate__slideInLeft">行业门户</p>
                    <p class="hl2 wow animate__animated animate__slideInLeft">一站式综合行业资源</p>
                    <p class="hl3 wow animate__animated animate__slideInLeft">
                        行业门户，是提供某行业综合信息资源的大型网站，
                        涵盖了行业产、供、销供应链及周边相关行业的企业、产品、商机、资讯，
                        是一站式信息聚合平台。威有科技既提供传统的行业门户建设，
                        也提供垂直行业门户建设。凭借多年的行业服务经验和、大的互联网信息资源，
                        威有科技能为企业构建框架成熟，赋予企业更多行业话语权。
                    </p>
                    <p class="hl4 wow animate__animated animate__slideInLeft">
                        网站功能：产品展示、公信力展示、SEO优化、在线订单、运营统计
                    </p>
                    <div class="hl5 wow animate__animated animate__slideInLeft">
                        <div class="hl5b">
                            更多介绍
                            <div class="hbCode">
                                <img :src="getWebData().wechat_visitor" alt="" />
                            </div>
                        </div>
                        <div class="hl5b">
                            我要做网站
                            <div class="hbCode">
                                <img :src="getWebData().public_phone" alt="" />
                            </div>
                        </div>
                        <div class="hl5b" @click="goMore">查看更多案例</div>
                    </div>
                    <div class="hl6 wow animate__animated animate__slideInLeft">
                        <ul>
                            <li class="h6Li" v-for="item in 8" :key="item">
                                <a href="#"> <img src="http://iv.okvu.cn/vugw/img/2022719661.png" alt="" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="hbRight">
                    <img
                        src="http://iv.okvu.cn/vugw/img/hc1551.png"
                        alt=""
                        class="hbr1 wow animate__animated animate__bounceInUp"
                    />
                </div>
            </div>
        </div>
        <div class="hbBox6">
            <div class="hb1Box">
                <div class="hbRight">
                    <img
                        src="http://iv.okvu.cn/vugw/img/hc1561.png"
                        alt=""
                        class="hbr1 wow animate__animated animate__slideInUp"
                    />
                    <img src="http://iv.okvu.cn/vugw/img/hc1562.png" alt="" class="hbr2 wow animate__animated animate__flipInX" />
                    <img
                        src="http://iv.okvu.cn/vugw/img/hc1563.png"
                        alt=""
                        class="hbr3 wow animate__animated animate__slideInUp"
                    />
                    <img src="http://iv.okvu.cn/vugw/img/hc1564.png" alt="" class="hbr4 wow animate__animated animate__flipInX" />
                </div>
                <div class="hbLeft">
                    <p class="hl1 wow animate__animated animate__slideInRight">H5活动网站</p>
                    <p class="hl2 wow animate__animated animate__slideInRight" data-wow-delay="0.1s">
                        利用H5的力量创造全民娱乐活动
                    </p>
                    <p class="hl3 wow animate__animated animate__slideInRight" data-wow-delay="0.2s">
                        H5活动网站是利用Html5技术建设宣传、运营、推广等活动页面，
                        相比普通的运营网站有更强的互动，更高的质量，更丰富的话题性。威有科技H5活动网站建设，
                        透彻洞悉用户行为，能策划多种热点活动，以爆炸性的页面设计，点燃用户活动激情，
                        留给用户深刻的印象，提升用户好感。利用全民娱乐的方式，并自发为企业宣传。
                    </p>
                    <p class="hl4 wow animate__animated animate__slideInRight" data-wow-delay="0.3s">
                        网站功能：产品展示、公信力展示、SEO优化、在线订单、 运营统计
                    </p>
                    <div class="hl5 wow animate__animated animate__slideInRight" data-wow-delay="0.4s">
                        <div class="hl5b">
                            更多介绍
                            <div class="hbCode">
                                <img :src="getWebData().wechat_visitor" alt="" />
                            </div>
                        </div>
                        <div class="hl5b">
                            我要做网站
                            <div class="hbCode">
                                <img :src="getWebData().public_phone" alt="" />
                            </div>
                        </div>
                        <div class="hl5b" @click="goMore">查看更多案例</div>
                    </div>
                    <div class="hl6 wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                        <ul>
                            <li class="h6Li" v-for="item in 8" :key="item">
                                <a href="#"> <img src="http://iv.okvu.cn/vugw/img/20175104214.jpg" alt="" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HsContent15",
    inject: ["getWebData"],
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
    methods: {
        goMore() {
            this.$router.push("/case");
        },
    },
};
</script>

<style lang="less" scoped>
.h15Box {
    width: 100%;
    margin-top: 78px;
    text-align: left;
    overflow: hidden;
    .hbBox1 {
        width: 100%;
        height: 757px;
        background: url("http://iv.okvu.cn/vugw/img/JS1.jpg");
        .hb1Box {
            max-width: 960px;
            height: 100%;
            margin: 0 auto;
            color: white;
            position: relative;
            .hbLeft {
                width: 100%;
                height: 100%;
                overflow: hidden;
                .hl1 {
                    width: 100%;
                    height: 48px;
                    line-height: 48px;
                    font-size: 46px;
                    margin-top: 160px;
                }
                .hl2 {
                    width: 100%;
                    height: 24px;
                    line-height: 24px;
                    font-size: 24px;
                    margin-top: 10px;
                }
                .hl3 {
                    width: 363px;
                    min-height: 102px;
                    margin-top: 20px;
                    font-size: 14px;
                }
                .hl4 {
                    width: 363px;
                    height: 40px;
                    font-size: 15px;
                    margin-top: 10px;
                }
                .hl5 {
                    width: 100%;
                    height: 52px;
                    margin-top: 10px;
                    .hl5b {
                        width: 112px;
                        height: 35px;
                        text-align: center;
                        line-height: 35px;
                        font-size: 16px;
                        border-radius: 5px;
                        border: 1px solid white;
                        float: left;
                        position: relative;
                        cursor: pointer;
                        .hbCode {
                            width: 100px;
                            height: 100px;
                            position: absolute;
                            top: -110px;
                            left: 5px;
                            border-radius: 10px;
                            overflow: hidden;
                            opacity: 0;
                            transition: 0.5s;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .hl5b:hover {
                        .hbCode {
                            opacity: 1;
                        }
                    }
                    .hl5b:nth-child(2),
                    .hl5b:nth-child(3) {
                        margin-left: 10px;
                    }
                }
                .hl6 {
                    width: 380px;
                    height: 104px;
                    margin-top: 10px;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-around;
                        flex-wrap: wrap;
                        .h6Li {
                            width: 94px;
                            height: 51px;
                            border-radius: 10px;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
            .hbRight {
                .hbr1 {
                    width: 403px;
                    height: 339px;
                    position: absolute;
                    top: 145px;
                    right: 20px;
                    z-index: 1;
                }
                .hbr2 {
                    width: 408px;
                    height: 252px;
                    position: absolute;
                    top: 345px;
                    right: -100px;
                    z-index: 2;
                }
            }
        }
    }
    .hbBox2 {
        width: 100%;
        height: 757px;
        background: url("https://iv.okvu.cn/vugw/img/JS2.jpg");
        .hb1Box {
            max-width: 960px;
            height: 100%;
            margin: 0 auto;
            position: relative;
            .hbRight {
                .hbr1 {
                    width: 900px;
                    height: 650px;
                    position: absolute;
                    top: 60px;
                    left: -250px;
                    z-index: 1;
                }
            }
            .hbLeft {
                width: 363px;
                height: 100%;
                float: right;
                .hl1 {
                    width: 100%;
                    height: 48px;
                    line-height: 48px;
                    font-size: 46px;
                    margin-top: 160px;
                }
                .hl2 {
                    width: 100%;
                    height: 24px;
                    line-height: 24px;
                    font-size: 24px;
                    margin-top: 10px;
                }
                .hl3 {
                    width: 363px;
                    min-height: 102px;
                    margin-top: 20px;
                    font-size: 14px;
                }
                .hl4 {
                    width: 363px;
                    height: 40px;
                    font-size: 15px;
                    margin-top: 10px;
                }
                .hl5 {
                    width: 100%;
                    height: 52px;
                    margin-top: 10px;
                    .hl5b {
                        width: 112px;
                        height: 35px;
                        text-align: center;
                        line-height: 35px;
                        font-size: 16px;
                        border-radius: 5px;
                        border: 1px solid #00afec;
                        color: #00afec;
                        float: left;
                        position: relative;
                        cursor: pointer;
                        .hbCode {
                            width: 100px;
                            height: 100px;
                            position: absolute;
                            top: -110px;
                            left: 5px;
                            border-radius: 10px;
                            overflow: hidden;
                            opacity: 0;
                            transition: 0.5s;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .hl5b:hover {
                        .hbCode {
                            opacity: 1;
                        }
                    }
                    .hl5b:nth-child(2),
                    .hl5b:nth-child(3) {
                        margin-left: 10px;
                    }
                }
                .hl6 {
                    width: 380px;
                    height: 104px;
                    margin-top: 10px;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-around;
                        flex-wrap: wrap;
                        .h6Li {
                            border-radius: 10px;
                            overflow: hidden;
                            width: 92px;
                            height: 51px;
                            border: 1px solid #f0f0f0;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .hbBox3 {
        width: 100%;
        height: 757px;
        background: url("http://iv.okvu.cn/vugw/img/JS3.jpg");
        .hb1Box {
            max-width: 960px;
            height: 100%;
            margin: 0 auto;
            color: white;
            position: relative;
            .hbLeft {
                width: 100%;
                height: 100%;
                overflow: hidden;
                .hl1 {
                    width: 100%;
                    height: 48px;
                    line-height: 48px;
                    font-size: 46px;
                    margin-top: 160px;
                }
                .hl2 {
                    width: 100%;
                    height: 24px;
                    line-height: 24px;
                    font-size: 24px;
                    margin-top: 10px;
                }
                .hl3 {
                    width: 363px;
                    min-height: 102px;
                    margin-top: 20px;
                    font-size: 14px;
                }
                .hl4 {
                    width: 363px;
                    height: 40px;
                    font-size: 15px;
                    margin-top: 10px;
                }
                .hl5 {
                    width: 100%;
                    height: 52px;
                    margin-top: 10px;
                    .hl5b {
                        width: 112px;
                        height: 35px;
                        text-align: center;
                        line-height: 35px;
                        font-size: 16px;
                        border-radius: 5px;
                        border: 1px solid white;
                        float: left;
                        position: relative;
                        cursor: pointer;
                        .hbCode {
                            width: 100px;
                            height: 100px;
                            position: absolute;
                            top: -110px;
                            left: 5px;
                            border-radius: 10px;
                            overflow: hidden;
                            opacity: 0;
                            transition: 0.5s;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .hl5b:hover {
                        .hbCode {
                            opacity: 1;
                        }
                    }
                    .hl5b:nth-child(2),
                    .hl5b:nth-child(3) {
                        margin-left: 10px;
                    }
                }
                .hl6 {
                    width: 380px;
                    height: 104px;
                    margin-top: 10px;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-around;
                        flex-wrap: wrap;
                        .h6Li {
                            border-radius: 10px;
                            overflow: hidden;
                            width: 94px;
                            height: 51px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
            .hbRight {
                .hbr1 {
                    width: 328px;
                    height: 456px;
                    position: absolute;
                    top: 150px;
                    right: -60px;
                    z-index: 1;
                }
                .hbr2 {
                    width: 380px;
                    height: 374px;
                    position: absolute;
                    top: 195px;
                    right: 180px;
                    z-index: 2;
                }
                .hbr3 {
                    width: 314px;
                    height: 284px;
                    position: absolute;
                    top: 340px;
                    right: 22px;
                    z-index: 3;
                }
            }
        }
    }
    .hbBox4 {
        width: 100%;
        height: 757px;
        background: url("http://iv.okvu.cn/vugw/img/JS4.jpg");
        .hb1Box {
            max-width: 960px;
            height: 100%;
            margin: 0 auto;
            position: relative;
            .hbRight {
                .hbr1 {
                    width: 599px;
                    height: 356px;
                    position: absolute;
                    top: 220px;
                    left: -100px;
                    z-index: 1;
                }
            }
            .hbLeft {
                width: 363px;
                height: 100%;
                float: right;
                .hl1 {
                    width: 100%;
                    height: 48px;
                    line-height: 48px;
                    font-size: 46px;
                    margin-top: 160px;
                }
                .hl2 {
                    width: 100%;
                    height: 24px;
                    line-height: 24px;
                    font-size: 24px;
                    margin-top: 10px;
                }
                .hl3 {
                    width: 363px;
                    min-height: 102px;
                    margin-top: 20px;
                    font-size: 14px;
                }
                .hl4 {
                    width: 363px;
                    height: 40px;
                    font-size: 15px;
                    margin-top: 10px;
                }
                .hl5 {
                    width: 100%;
                    height: 52px;
                    margin-top: 10px;
                    .hl5b {
                        width: 112px;
                        height: 35px;
                        text-align: center;
                        line-height: 35px;
                        font-size: 16px;
                        border-radius: 5px;
                        border: 1px solid #00afec;
                        color: #00afec;
                        float: left;
                        position: relative;
                        cursor: pointer;
                        .hbCode {
                            width: 100px;
                            height: 100px;
                            position: absolute;
                            top: -110px;
                            left: 5px;
                            border-radius: 10px;
                            overflow: hidden;
                            opacity: 0;
                            transition: 0.5s;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .hl5b:hover {
                        .hbCode {
                            opacity: 1;
                        }
                    }
                    .hl5b:nth-child(2),
                    .hl5b:nth-child(3) {
                        margin-left: 10px;
                    }
                }
                .hl6 {
                    width: 380px;
                    height: 104px;
                    margin-top: 10px;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-around;
                        flex-wrap: wrap;
                        .h6Li {
                            border-radius: 10px;
                            overflow: hidden;
                            width: 92px;
                            height: 51px;
                            border: 1px solid #f0f0f0;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .hbBox5 {
        width: 100%;
        height: 757px;
        background: url("http://iv.okvu.cn/vugw/img/JS5.jpg");
        .hb1Box {
            max-width: 960px;
            height: 100%;
            margin: 0 auto;
            color: white;
            position: relative;
            .hbLeft {
                width: 100%;
                height: 100%;
                overflow: hidden;
                .hl1 {
                    width: 100%;
                    height: 48px;
                    line-height: 48px;
                    font-size: 46px;
                    margin-top: 160px;
                }
                .hl2 {
                    width: 100%;
                    height: 24px;
                    line-height: 24px;
                    font-size: 24px;
                    margin-top: 10px;
                }
                .hl3 {
                    width: 363px;
                    min-height: 102px;
                    margin-top: 20px;
                    font-size: 14px;
                }
                .hl4 {
                    width: 363px;
                    height: 40px;
                    font-size: 15px;
                    margin-top: 10px;
                }
                .hl5 {
                    width: 100%;
                    height: 52px;
                    margin-top: 10px;

                    .hl5b {
                        width: 112px;
                        height: 35px;
                        text-align: center;
                        line-height: 35px;
                        font-size: 16px;
                        border-radius: 5px;
                        border: 1px solid white;
                        float: left;
                        position: relative;
                        cursor: pointer;
                        z-index: 5;
                        .hbCode {
                            width: 100px;
                            height: 100px;
                            position: absolute;
                            top: -110px;
                            left: 5px;
                            border-radius: 10px;
                            overflow: hidden;
                            opacity: 0;
                            transition: 0.5s;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .hl5b:hover {
                        .hbCode {
                            opacity: 1;
                        }
                    }
                    .hl5b:nth-child(2),
                    .hl5b:nth-child(3) {
                        margin-left: 10px;
                    }
                }
                .hl6 {
                    width: 380px;
                    height: 104px;
                    margin-top: 10px;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-around;
                        flex-wrap: wrap;
                        .h6Li {
                            border-radius: 10px;
                            overflow: hidden;
                            width: 94px;
                            height: 51px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
            .hbRight {
                .hbr1 {
                    width: 880px;
                    height: 455px;
                    position: absolute;
                    top: 140px;
                    right: -170px;
                    z-index: 1;
                }
            }
        }
    }
    .hbBox6 {
        width: 100%;
        height: 757px;
        background: url("http://iv.okvu.cn/vugw/img/JS2.jpg");
        .hb1Box {
            max-width: 960px;
            height: 100%;
            margin: 0 auto;
            position: relative;
            .hbRight {
                .hbr1 {
                    width: 300px;
                    height: 392px;
                    position: absolute;
                    top: 250px;
                    left: -120px;
                    z-index: 4;
                }
                .hbr2 {
                    width: 384px;
                    height: 304px;
                    position: absolute;
                    top: 180px;
                    left: -50px;
                    z-index: 3;
                }
                .hbr3 {
                    width: 410px;
                    height: 260px;
                    position: absolute;
                    bottom: 70px;
                    left: 70px;
                    z-index: 2;
                }
                .hbr4 {
                    width: 371px;
                    height: 404px;
                    position: absolute;
                    top: 75px;
                    left: 100px;
                    z-index: 1;
                }
            }
            .hbLeft {
                width: 363px;
                height: 100%;
                float: right;
                .hl1 {
                    width: 100%;
                    height: 48px;
                    line-height: 48px;
                    font-size: 46px;
                    margin-top: 160px;
                }
                .hl2 {
                    width: 100%;
                    height: 24px;
                    line-height: 24px;
                    font-size: 24px;
                    margin-top: 10px;
                }
                .hl3 {
                    width: 363px;
                    min-height: 102px;
                    margin-top: 20px;
                    font-size: 14px;
                }
                .hl4 {
                    width: 363px;
                    height: 40px;
                    font-size: 15px;
                    margin-top: 10px;
                }
                .hl5 {
                    width: 100%;
                    height: 52px;
                    margin-top: 10px;
                    .hl5b {
                        width: 112px;
                        height: 35px;
                        text-align: center;
                        line-height: 35px;
                        font-size: 16px;
                        border-radius: 5px;
                        border: 1px solid #00afec;
                        color: #00afec;
                        float: left;
                        position: relative;
                        cursor: pointer;
                        .hbCode {
                            width: 100px;
                            height: 100px;
                            position: absolute;
                            top: -110px;
                            left: 5px;
                            border-radius: 10px;
                            overflow: hidden;
                            opacity: 0;
                            transition: 0.5s;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .hl5b:hover {
                        .hbCode {
                            opacity: 1;
                        }
                    }
                    .hl5b:nth-child(2),
                    .hl5b:nth-child(3) {
                        margin-left: 10px;
                    }
                }
                .hl6 {
                    width: 380px;
                    height: 104px;
                    margin-top: 10px;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-around;
                        flex-wrap: wrap;
                        .h6Li {
                            border-radius: 10px;
                            overflow: hidden;
                            width: 92px;
                            height: 51px;
                            border: 1px solid #f0f0f0;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
